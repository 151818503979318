import PropTypes from 'prop-types';
import useLang from 'utils/hooks/use-lang';
import {
  renderCardSection,
  renderCardSlider,
  renderClubOffers,
  renderConfirmationDetailsSection,
  renderDealSection,
  renderFaqSection,
  renderGenericContentSection,
  renderImageCarouselSection,
  renderTextImage,
  renderImageSlider,
  renderJobVacanciesSection,
  renderLinkList,
  renderMapSection,
  renderMembershipSection,
  renderQuoteSection,
  renderSection,
  renderUspSection,
  renderVideoSection,
  renderBentoboxSection,
  renderSocialSection,
} from './render-sections';

const contentBlockMap = {
  section: renderSection,
  confirmationDetails: renderConfirmationDetailsSection,
  membershipSection: renderMembershipSection,
  newsSection: renderCardSection,
  videoSection: renderVideoSection,
  imageSection: renderTextImage,
  dealSection: renderDealSection,
  mapSection: renderMapSection,
  imageCarouselSection: renderImageCarouselSection,
  imageSliderSection: renderImageSlider,
  uspSection: renderUspSection,
  linkList: renderLinkList,
  clubOffers: renderClubOffers,
  genericContentSection: renderGenericContentSection,
  quoteSection: renderQuoteSection,
  jobVacanciesSection: renderJobVacanciesSection,
  cardSlider: renderCardSlider,
  bentoboxSection: renderBentoboxSection,
  faqSection: renderFaqSection,
  socialSection: renderSocialSection,
};

export function ContentBlocksSectionView({
  page,
  contentBlocks,
  club,
  onClickImageSlider,
  onClickAccessibleLabel,
  isPanel,
  specialists,
}) {
  const { lang } = useLang();

  return (
    contentBlocks &&
    contentBlocks.filter(Boolean).map((item, index) => {
      if (!contentBlockMap[item.type]) return null;
      const stickToBottom =
        item.backgroundColor &&
        item.backgroundColor === contentBlocks[index + 1]?.backgroundColor;
      const stickToTop =
        item.backgroundColor &&
        item.backgroundColor === contentBlocks[index - 1]?.backgroundColor;

      const key = item.id;

      return (
        <>
          {contentBlockMap[item.type]({
            key,
            stickToBottom,
            stickToTop,
            item,
            lang,
            club,
            page,
            onClickImageSlider,
            onClickAccessibleLabel,
            isPanel,
            specialists,
          })}
        </>
      );
    })
  );
}

ContentBlocksSectionView.propTypes = {
  isPanel: PropTypes.bool,
  contentBlocks: PropTypes.array,
  lang: PropTypes.string,
  club: PropTypes.object,
  onClickImageSlider: PropTypes.func,
  onClickAccessibleLabel: PropTypes.string,
  specialists: PropTypes.array,
};

ContentBlocksSectionView.defaultProps = {
  isPanel: false,
};

export default ContentBlocksSectionView;
