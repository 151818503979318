import styles from './index.module.scss';
import { IImage } from 'src/features/shared/contentful/types/IImage';
import { Image } from '@features/shared/components/image';
import Icons from 'features/shared/components/icons';
import {
  Body,
  FontColor,
  H4,
  Note,
} from 'src/features/shared/components/typography';

interface IClubsNearbyCardProps {
  description: string;
  image?: IImage;
  title: string;
  distance?: string;
}

export const ClubNearbyCard = ({
  description,
  image,
  title,
  distance,
}: IClubsNearbyCardProps) => (
  <div className={styles.card}>
    {image && (
      <div className={styles.header}>
        {image && <Image className={styles.image} image={image} />}
      </div>
    )}
    <div className={styles.body}>
      <div>
        <H4>{title}</H4>
        <Body color={FontColor.Neutral02}>{description}</Body>
      </div>
      {distance && (
        <div className={styles.footer}>
          <Icons.MarkerFilled className={styles.marker} />
          <Note color={FontColor.Neutral02}>{distance}</Note>
        </div>
      )}
    </div>
  </div>
);
