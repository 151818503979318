import React from 'react';
import { Image } from '@features/shared/components/image';
import classNames from 'classnames';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import {
  FontColor,
  H1,
  H2,
  H3,
  H4,
  H5,
} from 'src/features/shared/components/typography';
import useLang from 'utils/hooks/use-lang';
import styles from './SectionHeader.module.scss';
import { EntryFields } from 'contentful';
import { IImage } from '../../contentful/types/IImage';

type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface ISectionHeaderProps {
  headerImage?: IImage;
  image?: { src: string; alt: string };
  title?: string;
  titleStyle?: string;
  titleTag?: TitleTag;
  hasBorder?: boolean;
  isCentered?: boolean;
  description?: string | EntryFields.RichText;
  hasLightText?: boolean;
  className?: string;
}

const titleComponentMap: Record<TitleTag, typeof H1> = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
};

export function SectionHeader({
  headerImage,
  image,
  title,
  titleTag = 'h2',
  hasBorder,
  titleStyle,
  description,
  isCentered,
  hasLightText,
  className,
}: ISectionHeaderProps) {
  const { lang } = useLang();
  const { renderSectionDescription } = useRichTextRenderer(lang);

  const TitleComponent = titleComponentMap[titleTag];

  return (
    <>
      {headerImage && (
        <Image className={styles['header-image']} image={headerImage} />
      )}
      {title && (
        <div className={classNames(styles.header, className)}>
          {image && <Image className={styles.image} image={image} />}
          {title && (
            <TitleComponent
              typographyStyle={titleStyle}
              hasBorderTop={hasBorder}
              isCentered={isCentered}
              color={hasLightText && FontColor.Neutral06}
            >
              {title}
            </TitleComponent>
          )}
        </div>
      )}
      {description != null &&
        renderSectionDescription(description, styles.description)}
    </>
  );
}
