import { GENERIC_SECTION_TYPES } from 'features/shared/utils/constants';
import dynamic from 'next/dynamic';
import { BentoboxSection } from 'features/shared/components/sections/bentobox-section';
import { FaqSection } from 'src/features/shared/components/sections/faq-section';
import { TextImage } from 'src/features/shared/components/sections/text-image';
import { SocialSection } from 'src/features/shared/components/sections/social-section';
import mapItemsWithLink from 'utils/map-items-with-link';

/** JavaScript components - @TODO Replace me */
const VideoSection = dynamic(() => import('sections/video'));

const CardSection = dynamic(() =>
  import('@features/shared/components/sections/card-section').then(
    (component) => component.CardSection,
  ),
);
const ConfirmationDetailsSection = dynamic(() =>
  import('sections/confirmation-details'),
);
const UspSection = dynamic(() => import('sections/usp-section'));
const LinkList = dynamic(() =>
  import('features/shared/components/sections/link-list').then(
    (component) => component.LinkList,
  ),
);
const ClubOffers = dynamic(() =>
  import('features/shared/components/content-sections/club-offers'),
);
const ImageSlider = dynamic(() =>
  import('features/shared/components/content-sections/image-slider'),
);
const GroupLessons = dynamic(() =>
  import('@features/club-page/components/group-lessons'),
);
const Quote = dynamic(() => import('@features/club-page/components/quote'));
const Specialists = dynamic(() =>
  import('@features/club-page/components/specialists'),
);
const DealSection = dynamic(() =>
  import('@features/club-page/components/deal'),
);

/** TypeScript components */
const ImageCarouselSection = dynamic(() =>
  import('src/features/shared/components/image-carousel-section').then(
    (component) => component.ImageCarouselSection,
  ),
);
const CardSlider = dynamic(() =>
  import('@features/shared/components/card-slider').then(
    (component) => component.CardSlider,
  ),
);
const MapSection = dynamic(() =>
  import('@features/shared/components/sections/map/MapSection').then(
    (component) => component.MapSection,
  ),
);
const MembershipSection = dynamic(() =>
  import('src/features/shared/components/sections/membership-section').then(
    (component) => component.MembershipSection,
  ),
);

const ContentfulSection = dynamic(() =>
  import('src/features/shared/components/sections/contentful-section').then(
    (component) => component.ContentfulSection,
  ),
);
const JobVacanciesSection = dynamic(() =>
  import('@features/shared/components/job-vacancies-section').then(
    (component) => component.JobVacanciesSection,
  ),
);

export const renderSection = ({ item, club, stickToBottom, stickToTop }) => {
  return (
    <ContentfulSection
      item={item}
      stickToBottom={stickToBottom}
      stickToTop={stickToTop}
      club={club}
    />
  );
};

export const renderConfirmationDetailsSection = ({ item, lang }) => {
  return (
    <ConfirmationDetailsSection
      lang={lang}
      title={item.title}
      description={item.description}
      items={item.cards}
      label={item.linkLabel}
      link={item.link}
      key={item.id}
    />
  );
};

export const renderCardSection = ({ item }) => {
  return <CardSection key={item.id} title={item.title} items={item.items} />;
};

export const renderMembershipSection = ({
  item,
  club,
  page,
  stickToBottom,
  stickToTop,
}) => {
  return (
    <MembershipSection
      key={item.id}
      stickToBottom={stickToBottom}
      stickToTop={stickToTop}
      isClubPage={!!club}
      page={page}
      clubId={club ? club.pgClubId : null}
      {...item}
    />
  );
};

export const renderVideoSection = ({ item }) => {
  return (
    <VideoSection
      key={item.id}
      title={item.title}
      description={item.description}
      videoId={item.videoId}
      linkLabel={item.linkLabel}
      link={item.link}
    />
  );
};

export const renderTextImage = ({ item, stickToBottom, stickToTop }) => {
  return (
    <TextImage
      key={item.id}
      stickToBottom={stickToBottom}
      stickToTop={stickToTop}
      item={item}
    />
  );
};

export const renderImageCarouselSection = ({ item, lang, club }) => {
  const items = mapItemsWithLink(item.items, lang);
  return (
    <ImageCarouselSection
      key={item.id}
      title={item.title}
      description={item.description}
      hasLightbox={item.hasLightbox}
      anchorLinkTitle={item.anchorLinkTitle}
      items={items}
      isContentBlock
      slidesToShow={{
        xs: 1.01,
        sm: 2.2,
        md: 2.2,
        lg: 2.2,
      }}
      isClubPage={club !== undefined}
    />
  );
};

export const renderDealSection = ({
  item,
  page,
  stickToBottom,
  stickToTop,
}) => {
  return (
    <DealSection
      stickToBottom={stickToBottom}
      stickToTop={stickToTop}
      {...item}
      key={item.id}
      page={page}
    />
  );
};

export const renderMapSection = ({ item }) => {
  return <MapSection {...item} />;
};

export const renderUspSection = ({ item }) => {
  return <UspSection {...item} />;
};

export const renderLinkList = ({ item }) => {
  return <LinkList {...item} />;
};

export const renderClubOffers = ({ item, isPanel }) => {
  return <ClubOffers {...item} isPanel={isPanel} />;
};

export const renderImageSlider = ({
  item,
  onClickImageSlider,
  accessibleLabel,
  isPanel,
}) => (
  <ImageSlider
    {...item}
    onClickImage={onClickImageSlider}
    accessibleLabel={accessibleLabel}
    isPanel={isPanel}
  />
);

export const renderJobVacanciesSection = ({ item }) => {
  return <JobVacanciesSection {...item} />;
};

export const renderGenericContentSection = ({
  item,
  club,
  page,
  isPanel,
  specialists,
}) => {
  const { ...rest } = item;

  switch (item.componentType) {
    case GENERIC_SECTION_TYPES.GROUP_LESSONS:
      return (
        <GroupLessons
          {...rest}
          clubId={club.pgClubId}
          slug={page.slug}
          isPanel={isPanel}
        />
      );
    case GENERIC_SECTION_TYPES.SPECIALISTS:
      return (
        <Specialists
          {...rest}
          club={club}
          specialists={specialists}
          isPanel={isPanel}
        />
      );
    default:
      return null;
  }
};

export const renderQuoteSection = ({ item, isPanel }) => {
  return <Quote {...item} isPanel={isPanel} />;
};

export const renderCardSlider = ({ item }) => {
  return <CardSlider {...item} />;
};

export function renderBentoboxSection({ item }) {
  return <BentoboxSection {...item} />;
}

export function renderFaqSection({ item }) {
  return <FaqSection {...item} />;
}

export function renderSocialSection({ item }) {
  return <SocialSection {...item} />;
}
